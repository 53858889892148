/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../styles/case-study.scss';

const CaseStudy = ({ pageContext, ...rest }) => {
  return (
    <Layout {...rest}>
      <SEO
        title={`${pageContext.title} | Cashfree Payments`}
        description={pageContext.excerpt}
        url={`https://www.cashfree.com${pageContext.uri}`}
      />

      <section className="casestudy-hero">
        <div className="container">
          <div className="cs-hero-content">
            <div className="hero-content">
              <p className="small-title with-square">CUSTOMER STORIES</p>
              <h1>{pageContext.title}</h1>
            </div>

            <div className="cs-hero-image">
              {pageContext.caseStudyMeta?.featuredImage?.sourceUrl && (
                <img
                  src={pageContext.caseStudyMeta.featuredImage.sourceUrl}
                  alt={pageContext.caseStudyMeta.featuredImage.altText}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="cs-wrapper">
        <div className="small-container">
          <div className="row row-no-margin mobile-wrap align-baseline">
            <div className="cs-side-bar">
              {pageContext.caseStudyMeta?.productsUsed && (
                <>
                  <h3>Products Used</h3>
                  <div className="products-used">
                    {pageContext.caseStudyMeta.productsUsed.map((product) => (
                      <a
                        className="flex"
                        href={product.caseStudyProductMeta.productUrl?.url}
                        key={product.id}>
                        {product.caseStudyProductMeta.image && (
                          <img
                            style={{ marginRight: 6 }}
                            src={product.caseStudyProductMeta.image?.sourceUrl}
                            alt={
                              product.caseStudyProductMeta.image.altText ||
                              'image'
                            }
                          />
                        )}{' '}
                        {product.name}
                      </a>
                    ))}
                  </div>
                </>
              )}

              {pageContext.caseStudyMeta?.featureInFocus && (
                <>
                  <h3>Feature in focus:</h3>
                  <div className="feature-focus">
                    {pageContext.caseStudyMeta?.featureInFocus
                      .split(',')
                      .map((i) => (
                        <span key={i} className="tag grey-tag">
                          <Icon
                            name="tick"
                            fill="#6933D3"
                            width="14px"
                            height="14px"
                          />{' '}
                          {i}
                        </span>
                      ))}
                  </div>
                </>
              )}

              <h3>SHARE THIS ON:</h3>

              <div className="flex social-share">
                <a
                  href={encodeURI(
                    `https://www.linkedin.com/shareArticle?mini=true&url=https://cashfree-dev.netlify.app/${pageContext.uri}&title=${pageContext.title}&summary=${pageContext.excerpt}`,
                  )}
                  target="_blank"
                  rel="noopener noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    fill="#180048"
                    viewBox="0 0 14 15">
                    <path
                      fillRule="evenodd"
                      d="M0 1.647C0 .737.716 0 1.6 0c.424 0 .831.174 1.131.482.3.31.469.728.469 1.165 0 .91-.716 1.647-1.6 1.647-.884 0-1.6-.738-1.6-1.647Zm14 6.928c.027-1.782-1.154-3.345-2.84-3.754A3.497 3.497 0 0 0 8 5.7v-.345a.406.406 0 0 0-.4-.412h-2c-.22 0-.4.184-.4.412v8.234c0 .227.18.412.4.412h2c.221 0 .4-.185.4-.412V8.946c-.02-.827.54-1.55 1.328-1.713.47-.083.95.053 1.312.371.361.318.567.786.56 1.276v4.71c0 .227.179.412.4.412h2c.22 0 .4-.185.4-.412V8.575ZM3.2 5.352v8.234c0 .228-.18.412-.4.412h-2c-.221 0-.4-.184-.4-.412V5.352c0-.227.179-.411.4-.411h2c.22 0 .4.184.4.411Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  href={encodeURI(
                    `https://www.facebook.com/sharer/sharer.php?u=https://cashfree-dev.netlify.app/${pageContext.uri}`,
                  )}
                  target="_blank"
                  rel="noopener noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    fill="#180048"
                    viewBox="0 0 8 14">
                    <path d="M7.6 2.333H5.2a.79.79 0 0 0-.8.778v2.333h3.2a.283.283 0 0 1 .231.11c.054.072.07.164.041.248L7.28 7.513a.4.4 0 0 1-.376.265H4.4v5.833c0 .215-.18.389-.4.389H2c-.22 0-.4-.174-.4-.389V7.778H.4c-.22 0-.4-.174-.4-.39V5.834c0-.214.18-.389.4-.389h1.2V3.111C1.6 1.393 3.033 0 4.8 0h2.8c.22 0 .4.174.4.389v1.555c0 .215-.18.39-.4.39Z" />
                  </svg>
                </a>

                <a
                  href={encodeURI(
                    `https://twitter.com/intent/tweet?url=https://cashfree-dev.netlify.app/${pageContext.uri}&text=${pageContext.title}`,
                  )}
                  target="_blank"
                  rel="noopener noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="13"
                    fill="#180048"
                    viewBox="0 0 15 13">
                    <path d="M14.978 1.884a6.047 6.047 0 0 1-1.385 1.36v.42a9.415 9.415 0 0 1-2.716 6.646A9.043 9.043 0 0 1 4.33 13a9.018 9.018 0 0 1-4.081-.963.191.191 0 0 1-.113-.176v-.084c0-.11.088-.199.195-.199a6.555 6.555 0 0 0 3.834-1.405 3.271 3.271 0 0 1-2.913-1.956.202.202 0 0 1 .022-.193.193.193 0 0 1 .173-.082c.383.04.769.003 1.138-.107C1.194 7.541.15 6.365.001 4.925a.2.2 0 0 1 .08-.177.192.192 0 0 1 .19-.022c.372.168.775.256 1.182.26C.236 4.17-.29 2.62.173 1.212a.4.4 0 0 1 .681-.137 9.18 9.18 0 0 0 6.298 2.963 3.114 3.114 0 0 1-.09-.764C7.085 1.924 7.904.722 9.137.23a3.22 3.22 0 0 1 3.55.8 6.423 6.423 0 0 0 1.79-.635.125.125 0 0 1 .134 0 .132.132 0 0 1 0 .138 3.318 3.318 0 0 1-1.318 1.528 6.32 6.32 0 0 0 1.498-.367.118.118 0 0 1 .135 0 .13.13 0 0 1 .07.083.132.132 0 0 1-.018.108Z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="column" style={{ padding: 0 }}>
              <div className="cs-right-block">
                {pageContext.quote && (
                  <div
                    className="cs-testimonial"
                    style={{
                      display: pageContext.quote.quoteText ? '' : 'none',
                    }}>
                    {pageContext.quote.quoteText && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pageContext.quote.quoteText,
                        }}
                      />
                    )}

                    <div className="testimonial-author row row-no-margin justify-flex-start">
                      {pageContext.quote.quoteAuthorPhoto && (
                        <div className="testimonila-author-img">
                          <img
                            src={pageContext.quote.quoteAuthorPhoto.sourceUrl}
                            alt={pageContext.quote.quoteAuthorPhoto.altText}
                          />
                        </div>
                      )}
                      <div>
                        {pageContext.quote.quoteAuthor && (
                          <h4>{pageContext.quote.quoteAuthor}</h4>
                        )}
                        {pageContext.quote.quoteAuthorSalutation && (
                          <p>{pageContext.quote.quoteAuthorSalutation}</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {pageContext.content && (
                  <div
                    className="cs-content-wrapper"
                    dangerouslySetInnerHTML={{ __html: pageContext.content }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cs-related-main">
        <div className="container">
          <h2 className="heading center">Read more</h2>
          <div className="cs-related-wrapper">
            <div className="row mobile-wrap">
              {pageContext.posts
                .filter((post) => post.slug !== pageContext.slug)
                .splice(0, 3)
                .map(({ node: post }) => (
                  <div key={post.slug} className="column">
                    <div className="single-cs-related">
                      <div className="cs-related-thumb">
                        <Link to={post.uri}>
                          <img
                            src={post.featuredImage?.node.sourceUrl}
                            alt={post.featuredImage?.node.altText}
                          />
                        </Link>
                      </div>
                      <div className="cs-related-desc">
                        <h3>{post.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                        <Link className="link" to={post.uri}>
                          Read more
                          <img
                            src="/img/arrow-right-purple.svg"
                            style={{
                              width: '15px',
                              marginLeft: '10px',
                              position: 'relative',
                              top: '-2px',
                            }}
                            alt="arrow right"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
CaseStudy.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        altText: PropTypes.string,
        sizes: PropTypes.string,
        sourceUrl: PropTypes.string,
        srcSet: PropTypes.string,
      }),
    }).isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    quote: PropTypes.shape({
      quoteAuthor: PropTypes.string,
      quoteAuthorSalutation: PropTypes.string,
      quoteText: PropTypes.string,
      quoteAuthorPhoto: PropTypes.shape({
        sourceUrl: PropTypes.string,
        altText: PropTypes.string,
      }),
    }),
    caseStudyMeta: PropTypes.shape({
      productsUsed: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          caseStudyProductMeta: PropTypes.shape({
            image: PropTypes.shape({
              sourceUrl: PropTypes.string,
              altText: PropTypes.string,
            }),
            productUrl: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      ),
      featureInFocus: PropTypes.string,
      featuredImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
        altText: PropTypes.string,
      }),
    }),
    posts: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string }))
      .isRequired,
  }).isRequired,
};

export default CaseStudy;
